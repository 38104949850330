import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/ModalComponentStyles.scss';

const DocumentModalComponent = ({ onClose }) => {
  const contentRef = useRef();


  return (
    <div className="document-modal-overlay">
      <div className="document-modal">
        <button className="document-modal-close-button" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M23 6L6 23M6 6L23 23" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        </button>
        <div className="document-modal-content" ref={contentRef}>          
          <p className="scrollable-content">
          <h2>Автоматизация процесса расчета стоимости испытаний с помощью программного компонента Калькуляция для АО "НИЦ АЭС"</h2>
          <br />Отрасли: Технические испытания, исследования, анализ и сертификация<br />
          <br />Описание кейса:<br />
            АО «Научно-испытательный центр оборудования атомных электростанций» ведет свою историю с 1987г.<br />
            <br />Основные направления деятельности организации: Испытания специальной арматуры, элементов систем безопасности, оборудования и комплектующих для АЭС и других отраслей промышленности; Оказание услуг по сопровождению и технической поддержке эксплуатации арматуры атомных станций.<br />
            Бухгалтерский и кадровый учет организация ведет при помощи продуктов компании 1С. В определенный момент времени возникла потребность в автоматизации расчета стоимости проведения испытаний.<br />
            Компания ООО «Компьютерные программы» предложила свой вариант автоматизации.<br />
            <br />Для работы были задействованы специалисты-консультанты и программисты ООО «Компьютерные программы» и специалисты лаборатории АО "НИЦ АЭС".<br />
            Специалистами ООО «Компьютерные программы» были предложены два варианта решения вопроса. Первый вариант это доработка текущей системе на базе «1С:Бухгалтерия 8». Второй вариант это разработка системы на базе 1С:Библиотека стандартных подсистем. Второй вариант имел множество преимуществ, и самое главное работа специалистов лаборатории фиксировалась в их собственной системе, не затрагивая механизмов Бухгалтерии, что и стало ключевым моментом при выборе варианта.<br />
            <br />Вариант разработки системы понравился всем ключевым лицам, которые принимали решение по автоматизации. После получения согласия на автоматизацию специалисты ООО «Компьютерные программы» приступили к разработке технического задания на основании ранее предоставленных материалов, руководств и положений.<br />
            <br />Как результат была разработана система, которая учитывает различные аспекты учета затрат на проведение испытаний, с возможностью формирования актов на проведение работ. Это экономит время сотрудников, а также позволяет точно сформировать стоимость испытаний, исключая ошибки при расчете и оперативно вносить корректировки при изменении тарифов. Отдел Бухгалтерии своевременно выставляет счета на оплату и формирует акты, имея под рукой детализированную смету затрат по каждому этапу проведения испытаний.<br />
            Разработанная система позволяет также вести учет сотрудников и задавать им ставки по оплате труда, вести учет оборудования лаборатории, объектов испытаний. А также можно вести учет ресурсов, которые расходуются в процессе воздействия на объект испытаний.<br />
            <br />Но самым главным преимуществом системы является гибкий механизм формирования расчетных значений в итоговой смете. Пользователь сам может задать формулу, по которой будут вестись итоговые расчеты показателей, а также задать формулы для расчета промежуточных значений.<br />
            <br />Как итог работы в системе выступает документ Калькуляция работ, который рассчитывает смету по входящей «Анкете» заказчика и формирует справочно выходные печатные формы с детальными результатами расчёта сметы, договорной стоимости и затрат по ФОТ и материалам.<br />
            Сотрудничество АО НИЦ АЭС  и «ООО Компьютерные программы» продолжается, в настоящее время осуществляется консультационная поддержка пользователя по использованию программы.<br />
            Автоматизированы следующие функции:
              <br />Хранение нормативно-справочной информации по объектам испытаний
              <br />Хранение нормативно-справочной информации по стендам испытаний
              <br />Хранение нормативно-справочной информации по лабораторному оборудованию
              <br />Возможность формирования графиков испытаний для повторяющихся действий с объектом испытаний
              <br /><br />Хранение ресурсов Лаборатории (под ресурсами имеется в виду: материальное обеспечение Лаборатории, персонал, амортизация и т.д.)
              <br />Хранение информации об алгоритмах расчета показателей калькуляции
              <br />Хранение перечня работ, с указанием используемых ресурсов и алгоритмов расчета их затрат как в цикле (для повторяющихся работ), так и статике (для однократных работ)
              <br /><br />Формирование входящих Анкет от Заказчиков для фиксации перечня работ для проведения испытаний
              <br />Калькуляция расчета сметы по «Анкете» и формирование печатных форм с детальными результатами расчёта сметы, договорной стоимости и затрат по ФОТ и материалам.
              <br />Фиксация выполнения работ, рассчитанных по смете документом «Калькуляция», формирует выходные печатные формы «Акт выполненных работ», «детализация работ»
          </p>
          <button className="document-button button-style-4" onClick={onClose}>
            Закрыть
          </button>
          </div>
      </div>
    </div>
  );
};

DocumentModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DocumentModalComponent;